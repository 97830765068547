<template>
  <v-container class="px-0">
    <v-expansion-panels focusable multiple>
      <v-expansion-panel v-model="expanded">
        <v-expansion-panel-header>
          <template v-slot:default="{ open }">
            <v-fade-transition leave-absolute>
              <span v-if="open" style="text-align: -webkit-center">
                HISTÓRICO DE DADOS FUNCIONAIS
              </span>
              <div v-else> 
                <v-row>
                  <div class="mr-2">
                    <v-chip v-if="selectedItem.jobRegister">{{ selectedItem.jobRegister }}</v-chip>
                    <v-chip v-else>
                      Nenhuma matrícula registrada
                    </v-chip>
                  </div>
                  <div class="mr-2">
                    <v-chip v-if="selectedItem.post"> {{ selectedItem.post }}</v-chip>
                    <v-chip v-else>
                      Nenhum cargo registrado
                    </v-chip>
                  </div>
                  <div class="mr-2">
                    <v-chip v-if="selectedItem.cca">CCA - {{ selectedItem.cca }}</v-chip>
                    <v-chip v-else>
                      Nenhum CCA registrado
                    </v-chip>
                  </div>
                  <v-spacer></v-spacer>
                  <div  v-if="!selectedItem.jobRegister && !selectedItem.cca" class="pt-1 mr-4">
                    <v-icon color="green">mdi-plus-circle</v-icon>
                  </div>
                </v-row>
              </div>
            </v-fade-transition>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="rgba(0, 0, 0, 0.10)" class="pb-2">
          <v-data-table style="width: 100%;" :headers="headers" :items="items" item-key="name" class="elevation-1">
            <!--ACTIONS-->
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.actions="{ item }">
              <div class="d-flex flex-no-wrap">
                <v-icon v-if="item.active === true && !item.is_editing && !item.exoneration"
                  size="x-large" color="red" @click="editItem(item)" title="Exonerar">
                  mdi-alert-octagon-outline
                </v-icon>
                <!--ICONE 3 PONTOS QUE ABRE OPÇÃO DE EXCLUIR E EDITAR-->
                <v-menu bottom left v-if="item !== items[items.length - 1] && !item.is_editing">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon :class="{'pl-12': item.exoneration}" v-if="item.active === true" color="black" size="x-large">mdi-dots-vertical
                      </v-icon>
                      <v-icon v-else class="pl-12" color="black" size="x-large">mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <!--LISTA DOS ITENS DOS 3 PONTOS-->
                  <v-list>
                    <!--OPÇÃO DE EDITAR-->
                    <v-list-item @click="editItem(item)">
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                    <!--OPÇÃO DE EXCLUIR-->
                    <v-list-item @click="confirmDeleteItem(item)">
                      <v-list-item-title>Deletar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!--ícone para cancelar edição-->
                <v-icon v-if="item.is_editing" size="x-large" color="red" @click="confirmNotEditItem(item)" title="Cancelar">
                  mdi-cancel
                </v-icon>
                <v-icon v-if="item.is_editing" class="pl-1" size="x-large" color="green" @click="confirmUpdateCargo(item)" title="Confirmar">
                  mdi-check-circle
                </v-icon>
              </div>
              <v-icon class="pl-7" v-if="item === items[items.length - 1]" size="x-large" color="green" @click="confirmRegistraCargo(item)">
                mdi-plus-circle
              </v-icon>
            </template>
            <!-- Campos de entrada para informações do cargo -->

            <!--CARGO-->
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.post="{ item }">
              <v-textarea rows="1" auto-grow v-if="item === items[items.length - 1] || item.is_editing" v-model="item.post"
                :color="item.color" :rules="[rules.required]" label="Cargo" single-line></v-textarea>
              <template v-else>
                <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.post
                  }}</span>
              </template>
            </template>
            <!--Vínculo-->
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.bond="{ item }">
              <v-textarea auto-grow rows="1" v-if="item === items[items.length - 1] || item.is_editing" v-model="item.bond"
                :color="item.color" :rules="[rules.required]" label="Vínculo" single-line></v-textarea>
              <template v-else>
                <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.bond
                  }}</span>
              </template>
            </template>
            <!--JOBREGISTER-->
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.jobRegister="{ item }">
              <v-text-field auto-grow rows="1" v-if="item === items[items.length - 1] || item.is_editing" v-model="item.jobRegister" v-mask="'##########'" label="Matrícula" single-line
                :rules="[rules.required]"></v-text-field>
              <template v-else>
                <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.jobRegister
                  }}</span>
              </template>
            </template>

            <!--FUNÇÃO-->
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.role="{ item }">
              <v-textarea rows="1" auto-grow v-if="item === items[items.length - 1] || item.is_editing" v-model="item.role" label="Função"
                :rules="[rules.required]" single-line>
              </v-textarea>
              <template v-else>
                <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.role
                  }}</span>
              </template>
            </template>

            <!--DATA DE ENTRADA-->
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.entryDate="{ item }">
              <v-text-field v-if="item === items[items.length - 1] || item.is_editing" v-model="item.entryDate" label="Admissão*" type="date"
              :rules="[rules.required, validateDate]"></v-text-field>
              <template v-else>
                <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{
                  formatDate(item.entryDate) }}</span>
              </template>
            </template>

            <!--EXONERAÇÃO-->
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.exoneration="{ item }">
              <v-text-field v-if="item === items[items.length - 1] || item.is_editing" v-model="item.exoneration" label="Exoneração" type="date"
              :rules="[isExonerationDateValid]"></v-text-field>
              <template v-else>
                <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{
                  formatDate(item.exoneration) }}</span>
              </template>
            </template>

            <!--SIMBOLO-->
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.symbol="{ item }">
              <v-textarea auto-grow rows="1" v-if="item === items[items.length - 1] || item.is_editing" v-model="item.symbol"
                :color="item.color" label="Símbolo" single-label></v-textarea>
              <template v-else>
                <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.symbol
                  }}</span>
              </template>
            </template>

            <!--CCA-->
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.cca="{ item }">
              <v-textarea rows="1" auto-grow v-if="item === items[items.length - 1] || item.is_editing" v-model="item.cca"
                :color="item.color" :rules="[rules.required]" label="CCA*" single-line></v-textarea>
              <template v-else>
                <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.cca
                  }}</span>
              </template>
            </template>

            <!--ORGÃO DE ORIGEM-->
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.originOrganization="{ item }">
              <v-textarea auto-grow rows="1" v-if="item === items[items.length - 1] || item.is_editing" v-model="item.originOrganization"
                :color="item.color" label="Orgão Origem" single-line></v-textarea>
              <template v-else>
                <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.originOrganization
                  }}</span>
              </template>
            </template>

            <!--BÔNUS PÓS APOSENTADORIA-->
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.bonusPostRetirement="{ item }">
              <v-select v-if="item === items[items.length - 1] || item.is_editing" v-model="item.bonusPostRetirement" :items="bonusPostRetirementSelector" item-value="value"
                :rules="[rules.required]"></v-select>
              <template v-else>
                <span :style="{ color: item.exoneration && item.exoneration.length > 0 ? 'gray' : 'black' }">{{ item.bonusPostRetirement ?
                  'Sim' : 'Não' }}</span>
              </template>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <!--PAINEL DA LOTAÇÃO-->
      <v-expansion-panel>
        <v-expansion-panel-header>
          <template v-slot:default="{ open }">
            <v-fade-transition leave-absolute>
              <span v-if="open" style="text-align: -webkit-center">
                <v-icon color="cyan"></v-icon>
                HISTÓRICO DE LOTAÇÕES POR MATRÍCULA
              </span>
              <div v-else>
                <v-row>
                  <div v-if="personSectors.length > 0 ">
                    <v-chip class="mr-2" v-for="(item, index) in personSectors" :key="index">{{ item.isLeadership ? 'CHEFIA - ' : '' }}{{ item.acronym }}</v-chip>
                  </div>
                  <v-chip v-else>Nenhuma lotação registrada</v-chip>
                  <v-spacer></v-spacer>
                </v-row>
              </div>
            </v-fade-transition>
          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="rgba(0, 0, 0, 0.10)" class="pb-2">
          <allocation-table :person="person" @update-allocation="fetchAllocations"></allocation-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <!-- Diálogo de confirmação de modificação -->
    <template>
      <v-row justify="center">
        <v-dialog v-model="dialog_update" style="word-break: break-word;" persistent max-width="420" transition="dialog-bottom-transition">
          <v-card class="elevation-10">
            <v-card-title class="text-h5 d-flex align-center" color="primary lighten-5">
              <span class="">{{ dialog_phrase }}</span>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-actions class="justify-end px-4">
              <v-btn color="red" text class="elevation-1" @click="cancelDialog">
                <v-icon left>mdi-close</v-icon>
                  Não
              </v-btn>
              <v-btn color="green" class="elevation-1 white--text" text-color="white" @click="confirmDialog">
                <v-icon left>mdi-check</v-icon>
                Sim
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-container>
</template>

<script>

const urlbackend = process.env.VUE_APP_API_URL
import axios from 'axios'
import AllocationTable from './AllocationTable.vue';

export default {
  components: {
    AllocationTable
  },
  watch: {
    person: {
      handler() {
        if(this.person.ID){
          this.fetchCareerData();
          this.fetchAllocations();
        }
      },
      deep: true,
      immediate: true
    },
    // items: {
    //   deep: true,
    // },
  },
  props: {
    value: Array,
    person: Object,
    update: Boolean,
  },
  data() {
    return {
      personSectors: [],
      expanded: [],
      dialog_update: false,
      confirm_update: false,
      dialog_phrase: "",
      itemToModify: null,
      actionToConfirm: null,
      // Objeto para armazenar o item que está sendo editado
      copia_item: {},
      bonusPostRetirementSelector: ["Sim", "Não"],
      rules: {
        required: (value) => !!value || "Obrigatório"
      },
      fetchedPerson: {},
      headers: [
        { text: 'MATRÍCULA', value: 'jobRegister', sortable: false, align:'center', width: '120px' },
        { text: 'CARGO', value: 'post', sortable: false, width: '120px', align:'center' },
        { text: 'VÍNCULO', value: 'bond', sortable: false, width: '120px', align:'center' },
        { text: 'FUNÇÃO', value: 'role', sortable: false, align:'center', width: '120px' },
        { text: 'ADMISSÃO', value: 'entryDate', sortable: false , width:'120px', align:'center'},
        { text: 'EXONERAÇÃO', value: 'exoneration', sortable: false,  width: '120px', align:'center' },
        { text: "SÍMBOLO", value: "symbol", sortable: false, width: '120px', align:'center' },
        { text: "CCA", value: "cca", sortable: false, width: '100px', align:'center' },
        { text: "ORGÃO ORIGEM", value: "originOrganization", sortable: false,  width: '130px', align:'center' },
        { text: "ABONO DE PERMANÊNCIA", value: "bonusPostRetirement", sortable: false, align:'center' },
        { text: "AÇÕES", value: "actions", align:'center', sortable: false }
      ],
      items: [
        {
          jobRegister: '',
          post: '',
          role: '',
          bond: '',
          entryDate: '',
          exoneration: '',
          cca: '',
          symbol: '',
          originOrganization: '',
          bonusPostRetirement: '',
          is_editing: false,
          is_disabled: false
        }
      ],
      selectedItem: {}
    }
  },
  mounted() {
    this.fetchCareerData();

    // Adiciona o objeto para um novo cargo no final do array
    this.items.push(this.createEmptyCareer());

  },
  methods: {
    async fetchAllocations(){
      try {
        const urlGet = this.person?.ID ? `${urlbackend}/rh/allocation/person/${this.person.ID}` : `${urlbackend}/rh/allocation/`;
        const response = await axios.get(urlGet);
        this.personSectors = response.data.filter(item => item.allocated)
        .map(item => ({
          isLeadership: item.isLeadership,
          acronym: item.sectors?.acronym, 
        }))
        .sort(item => item.isLeadership ? -1 : 1);

        this.$emit('update-allocations');
      } catch (error) {
        console.error('Erro ao buscar dados de alocação:', error);
        this.personSectors = [];
      }
    },
    //Usado para formatar a data para o formato yyyy-mm-dd
    formatNewDate(value) {
    if (!value) return '';
    var date = new Date(value);
    var userTimezoneOffset = date.getTimezoneOffset() * 60000;
    date = new Date(date.getTime() + userTimezoneOffset);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  },
    // Tira o formato ISO da data para exibir pro usuário (##/##/####)
    formatDate(value) {
      if (!value) return ''
      var date = new Date(value)
      var userTimezoneOffset = date.getTimezoneOffset() * 60000;
      date = new Date(date.getTime() + userTimezoneOffset);
      const day = date.getDate().toString().padStart(2, '0')
      const month = (date.getMonth() + 1).toString().padStart(2, '0')
      const year = date.getFullYear()
      return `${day}/${month}/${year}`
      
    },
    validateDate(value) {
      if (!value || typeof value !== 'string') return '';
      
      const [year] = value.split('-').map(Number);

      // Verifique se o ano é válido
      if (year < 1800 || year > 3000) {
        return "Ano inválido.";
      }

      return true;
    },
    isExonerationDateValid(exonerationDate) {
      if (!exonerationDate || typeof exonerationDate !== 'string') return '';
      const item = this.items.find(i => i.is_editing || i === this.items[this.items.length - 1]);
      if (!item) return true;

      const [yearEx,monthEx, dayEx] = exonerationDate.split('-').map(Number);
      const [yearAd, monthAd, dayAd] = item.entryDate.split('-').map(Number);
      const exoneration = new Date(yearEx, monthEx - 1, dayEx);
      const admission = new Date(yearAd, monthAd - 1, dayAd);

      if (exoneration < admission) {
        return "Data de exoneração anterior a data de admissão"
      }

      return true
    },
    createEmptyCareer() {
    return {
      jobRegister: '',
      post: '',
      role: '',
      bond: '',
      entryDate: '',
      exoneration: '',
      cca: '',
      symbol: '',
      originOrganization: '',
      bonusPostRetirement: 'Não', //Valor Padrao
      is_editing: false,
      is_disabled: false,
    };
  },
  async fetchCareerData() {
  const urlGet = this.person?.ID ? `${urlbackend}/rh/person/${this.person.ID}` : `${urlbackend}/rh/person/`;

  try {
    const res = await axios.get(urlGet);
    if (res.status === 200 && res.data) {
      if (this.$parent || this.$root) {
        const person = res.data;
        person.birthday = new Date(person.birthday);
        this.fetchedPerson = person;
      }

      this.items = res.data.careers.map(el => ({
        ...el,
        is_disabled: false,
        is_editing: false,
      }));

      //Variável usada para mostrar o cargo no painel de cargo retraído
      this.selectedItem = this.items[this.items.length-1];

      // Adiciona o objeto para um novo cargo no final do array
      this.items.push(this.createEmptyCareer());
    } else {
      this.items = [this.createEmptyCareer()];
    }
  } catch (error) {
    console.error('Erro ao buscar dados de carreira:', error);
    this.items = [this.createEmptyCareer()];
  }
},
    async confirmDialog() {
      this.dialog_update = false;
      if (this.actionToConfirm) {
        await this.actionToConfirm();
      }
      this.actionToConfirm = null;
    },
    cancelDialog() {
      this.dialog_update = false;
      this.actionToConfirm = null;
    },
    async confirmUpdateCargo(item) {
      this.dialog_phrase = 'Tem certeza que deseja atualizar este cargo?';
      this.dialog_update = true;
      this.itemToModify = item;
      this.actionToConfirm = this.updateCargo;
    },
    // Atualiza o cargo -> Atualiza os campos do cargo
    async updateCargo() {
      const item = this.itemToModify;

      if (item.jobRegister === '' || item.post === '' || item.department === '' || item.role === '' || item.bond === '' || item.entryDate === '' || item.isBoss === '' || item.cca === '' || item.workCity === '' || item.bonusPostRetirement === '' || (item.isBoss === "Sim" && item.bossSector === '')) {
        alert('Preencha todos os campos obrigatórios.');
        return;
      }

      // Garante que a data de exoneração esteja em formato ISO antes de enviar
      if (!item.exoneration) {
        item.exoneration = null;
      }

      // Transforma o bonusPostRetirement em booleano para enviar ao backend
      item.bonusPostRetirement = item.bonusPostRetirement === "Sim";

      // Retira os campos que não são necessários para enviar ao backend
      const cargoToSend = { ...item };
      delete cargoToSend.is_editing;
      delete cargoToSend.is_disabled;
      delete cargoToSend.createdAt;
      delete cargoToSend.updatedAt;
      delete cargoToSend.ID;
      delete cargoToSend.user;

      // Faz com que o exoneration não seja mandado para o backend, caso um cargo não tenha essa data.
      if (!cargoToSend.exoneration) delete cargoToSend.exoneration;

      // Envia o cargo atualizado ao backend - PUT
      try {
        const res = await axios.put(urlbackend + '/rh/career/' + item.ID, cargoToSend);
        if (res.status >= 200 && res.status <= 299) {
          item.is_editing = false;
          await this.fetchCareerData();
          this.$emit('update-careers', this.fetchedPerson.ID);
        }
      } catch (error) {
        console.error('Erro ao atualizar o cargo:', error);
        alert('Ocorreu um erro ao atualizar o cargo. Por favor, tente novamente.');
      }

      // Retorna a variável de confirmação de update para o estado inicial
      this.confirm_update = false;
    },
    cancelUpdate() {
      this.dialog_update = false;
      this.confirm_update = false;
    },
    async confirmDeleteItem(item) {
      this.dialog_phrase = 'Tem certeza que deseja excluir este item?';
      this.dialog_update = true;
      this.itemToModify = item;
      this.actionToConfirm = this.deleteItem;
    },
    async deleteItem() {
      const item = this.itemToModify;
      axios.delete(urlbackend + '/rh/career/' + item.ID).then((res) => {
        if (res.status >= 200 && res.status <= 299) {
          const index = this.items.indexOf(item);
          if (index > -1) {
            this.items.splice(index, 1);
          }
        }
      }).catch((error) => {
        console.error('Erro ao excluir o item:', error);
        alert('Ocorreu um erro ao excluir o item. Por favor, tente novamente.');
      });
    },
    async confirmExonerateItem(item) {
      this.dialog_phrase = 'Tem certeza que deseja exonerar este cargo?';
      this.dialog_update = true;
      this.itemToModify = item;
      this.actionToConfirm = this.exonerateItem;
    },
    async exonerateItem() {
      const item = this.itemToModify;
      item.is_disabled = true;
      if (!item.exoneration) {
        item.exoneration = new Date();
      }

      // Cria uma cópia do item e remove os atributos desnecessários
      const itemToSend = { ...item };
      delete itemToSend.is_disabled;
      delete itemToSend.is_editing;
      delete itemToSend.createdAt;
      delete itemToSend.updatedAt;
      delete itemToSend.ID;

      try {
        const res = await axios.put(`${urlbackend}/rh/career/${item.ID}`, itemToSend);
        if (res.status >= 200 && res.status <= 299) {
          // Atualize a lista de itens
          await this.fetchCareerData();
          this.$emit('update-careers', this.fetchedPerson.ID);
        }
      } catch (error) {
        console.error('Erro ao exonerar o cargo:', error);
        alert('Ocorreu um erro ao exonerar o cargo. Por favor, tente novamente.');
      }
    },
    editItem(item) {
      //Copia o item para caso o usuário cancele a edição
      this.copia_item = { ...item }
      item.is_editing = true

      //Converte o bonusPostRetirement para visualização do usuário
      item.bonusPostRetirement = item.bonusPostRetirement ? "Sim" : "Não";

      //Converte as datas para o formato yyyy-mm-dd para visualização do usuário
      item.exoneration = this.formatNewDate(item.exoneration);
      item.entryDate = this.formatNewDate(item.entryDate);
      
    },
    async confirmRegistraCargo(item) {
      const requiredFields = [
        'jobRegister', 'post', 'department', 'role', 'bond', 'entryDate',
        'isBoss', 'cca', 'workCity', 'bonusPostRetirement'
      ];
      // Verifica se todos os campos obrigatórios estão preenchidos
      const isFieldEmpty = requiredFields.some(field => item[field] === '');
      
      if (isFieldEmpty) {
        alert('Preencha todos os campos obrigatórios.');
        return;
      }
      

      // this.dialog_phrase = 'Tem certeza que deseja registrar este cargo e exonerar o cargo anterior?';
      // this.dialog_update = true;
      this.itemToModify = item;
      // this.actionToConfirm = this.registraCargo;
      this.registraCargo();
    },
    async registraCargo() {
      console.log(this.itemToModify)
      const item = this.itemToModify;

      const cargo = {
        jobRegister: item.jobRegister,
        post: item.post,
        role: item.role,
        bond: item.bond,
        entryDate: item.entryDate,
        is_disabled: false,
        is_editing: false,
        cca: item.cca,
        symbol: item.symbol,
        originOrganization: item.originOrganization,
        bonusPostRetirement: item.bonusPostRetirement === "Sim",
        active: true,
        personId: this.person.ID,
      };

      this.items.splice(this.items.length - 1, 0, cargo);
      const cargoToSend = { ...cargo };
      delete cargoToSend.is_editing;
      delete cargoToSend.is_disabled;

      try {
        // Envia o cargo ao backend - POST
        const res = await axios.post(`${urlbackend}/rh/career`, cargoToSend);
        if (res.status >= 200 && res.status <= 299) {
          cargo.ID = res.data.ID;
          // Exonera o cargo anterior
          await this.fetchCareerData();
          this.$emit('update-careers', this.fetchedPerson.ID);
        }
      } catch (error) {
        console.error('Erro ao registrar o cargo:', error);
        alert('Ocorreu um erro ao registrar o cargo. Por favor, tente novamente.');
      }
},
    async confirmNotEditItem(item) {
      this.dialog_phrase = 'Tem certeza que deseja cancelar a edição?';
      this.dialog_update = true;
      this.itemToModify = item;
      this.actionToConfirm = this.notEditItem;
    },
    async notEditItem() {
      const item = this.itemToModify;
      const copia = this.copia_item;

      // Retorna os valores originais do item, antes da alteração do usuário.
      Object.keys(copia).forEach(key => {
        item[key] = copia[key];
      });
      item.is_editing = false;
    },
  }
}
</script>