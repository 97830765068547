<template>
  <v-card>
    <v-container>
      <v-form ref="form" v-model="valid">
        <!-- NOME -->
        <v-row>
          <v-text-field filled label="Nome" required v-model="name" :rules="[rules.required]">
          </v-text-field>
        </v-row>
        <v-row>
          <!--Coluna da esquerda-->
          <v-col class="mr-2" cols="5.5">
            <!-- E-MAIL INSTITUCIONAL-->
            <v-row>
              <v-text-field filled label="Email institucional" suffix="@pge.ms.gov.br" v-model="email_inst">
              </v-text-field>
            </v-row>
            <!-- E-MAIL PESSOAL-->
            <v-row>
              <v-text-field filled label="Email pessoal" v-model="personalEmail" :rules="[rules.required, rules.email]">
              </v-text-field>
            </v-row>
            <!-- NASCIMENTO -->
            <v-row>
              <v-col cols="10" class="pa-0">
                <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y
                  max-width="290px" min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="birthdayFormated" label="Nascimento" hint="DD/MM/YYYY" persistent-hint
                      v-bind="attrs" @blur="datanasc = parseDate(birthdayFormated)"
                      :rules="[rules.required, validateDate]" v-on="on" filled type="text"
                      v-mask="'##/##/####'"></v-text-field>
                  </template>
                  <v-date-picker v-model="datanasc" no-title @input="menu = false"></v-date-picker>
                </v-menu>
              </v-col>

              <!--IDADE-->
              <v-col cols="2" class="py-0 pr-0">
                <v-text-field filled label="Idade" v-model="age" disabled>
                </v-text-field>
              </v-col>
            </v-row>

            <!-- GENERO -->
            <v-row>
              <v-select filled label="Gênero" v-model="gender" :items="genderSelector" :rules="[rules.required]">
              </v-select>
            </v-row>

            <!-- TEM FILHOS? -->
            <v-row>
              <v-select filled label="Tem filhos?" v-model="hasChildren" :items="hasChildrenSelector"
                :rules="[rules.required]">
              </v-select>
            </v-row>

            <!-- Estado Civil -->
            <v-row>
              <v-select filled label="Estado civil" v-model="maritalStatus" :items="maritalStatusSelector"
                :rules="[rules.required]" item-text="text" item-value="value">
              </v-select>
            </v-row>
          </v-col>

          <!--Coluna da direita-->
          <v-col cols="5.5" class="ml-2">
            <!-- CPF -->
            <v-row>
              <v-text-field filled label="CPF" required v-model="cpf" :rules="[rules.required]" 
              :append-icon="showCpf ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showCpf = !showCpf" :type="showCpf ? 'text' : 'password'"
              v-mask="'###.###.###-##'">
              </v-text-field>
            </v-row>
            <!-- RG -->
            <v-row>
              <v-text-field filled label="RG" v-model="rg" :rules="[rules.required]" 
              :append-icon="showRg ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="showRg = !showRg" 
              :type="showRg ? 'text' : 'password'"
              v-mask="'#############'">
              </v-text-field>
            </v-row>
            <!--Orgão Emissor-->
            <v-row>
              <v-text-field filled label="Orgão Emissor" v-model="issuingBody" :rules="[rules.required]">
              </v-text-field>
            </v-row>
            <!-- UF -->
            <v-row>
              <v-text-field filled label="UF" required v-model="uf" :rules="[rules.required]" v-mask="'AA'">
              </v-text-field>
            </v-row>
            <!-- CELULAR -->
            <v-row>
              <v-text-field filled label="Celular" required v-model="phone" v-mask="'(##) # ####-####'" :rules="[rules.required]">
              </v-text-field>
            </v-row>

            <!-- AUTODECLARADO -->
            <v-row>
              <v-select filled label="Autodeclarado" v-model="selfDeclared" :items="selfDeclaredSelector"
                :rules="[rules.required]" item-text="text" item-value="value">
              </v-select>
            </v-row>

          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <span class="pa-0" v-if="update">
      <v-chip outlined class="mb-2">
        <v-icon left>
          mdi-account-circle-outline
        </v-icon>
        Registros de cargos - {{ this.person.name }}
      </v-chip>
      <role-table :person="person" :update="update" @update-careers="$emit('update-careers', $event)" @update-allocations="$emit('update-allocations')"></role-table>
    </span>
    <v-card-actions class="pr-0 mt-4">
      <v-spacer></v-spacer>
      <v-btn color="red" text @click="close">Cancelar</v-btn>
      <v-btn color="primary" @click="submitForm">{{ update ? 'Atualizar' : "Registrar" }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import RoleTable from "./RoleTable.vue";
import axios from 'axios'
const urlbackend = process.env.VUE_APP_API_URL

export default {
  components: {
    RoleTable,
  },
  props: {
    person: {
      type: Object,
    },
    update: {
      type: Boolean,
    },
  },
  watch: {
    person: function (val) {
      //Verifica se a person não seja um objeto vazio, ou seja, um novo usuário.
      if (val && Object.keys(val).length > 0) {
        this.showCpf = false,
        this.showRg = false,
        this.populatePersonData()
      }
      //Novo usuário
      else{
        this.showCpf = true,
        this.showRg = true,
        this.populatePersonData()
      }
    },
    datanasc(newVal) {
      this.birthdayFormated = this.formatDate(this.datanasc)
      this.age = this.calculateAge(newVal);
    },
  },
  data(vm) {
    return {
      showCpf: true,
      showRg: true,
      valid: false,
      rules: {
        required: (value) => !!value || "Campo Obrigatório",
        email: (value) => !value || /.+@.+\..+/.test(value) || 'Email inválido',
      },
      datanasc: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      age: '',
      birthdayFormated: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      menu: false,
      menu2: false,
      name: "",
      email_inst: "",
      personalEmail: "",
      cpf: "",
      rg: "",
      issuingBody: "",
      uf: "",
      phone: "",
      hasChildren: "",
      maritalStatus: "",
      selfDeclared: "",
      selfDeclaredSelector: [
        { text: "Branco(a)", value: 'branco' }, 
        { text: "Pardo(a)", value: 'pardo' },
        { text: "Negro(a)", value: 'negro' },
        { text: "Amarelo(a)", value: 'amarelo' },
        { text: "Indígena", value: 'indio' },
        { text: "Não Declarado/Sem Acesso", value: 'naoDeclarado' }
      ],
      maritalStatusSelector: [
        { text: "Solteiro(a)", value: 'solteiro' },
        { text: "Casado(a)", value: 'casado' },
        { text: "União Estável", value: 'uniaoEstavel' },
        { text: "Divorciado(a)", value: 'divorciado' },
        { text: "Viúvo(a)", value: 'viuvo' }
      ],
      genderSelector: ["Masculino", "Feminino", "Outros", "Não me identifico"],
      hasChildrenSelector: ["Sim", "Não"],
      dependents: "",
      roles: [],
      gender: "",
      showRoleTable: false
    };
  },
  mounted() {
    if (this.person && Object.keys(this.person).length > 0) {
      //Documentos sigilosos para servidores já criados.
      this.showCpf = false,
      this.showRg = false,
      this.populatePersonData()
    }
    else{
      this.showCpf = true,
      this.showRg = true,
      this.populatePersonData()
    }
  },

  methods: {   
    populatePersonData() {
        this.id = this.person.ID
        this.gender = this.person.gender;
        this.name = this.person.name
        this.cpf = this.person.cpf
        this.issuingBody = this.person.issuingBody
        this.uf = this.person.uf
        this.phone = this.person.phone
        this.hasChildren = this.person.hasChildren ? "Sim" : "Não"
        this.maritalStatus = this.person.maritalStatus
        this.selfDeclared = this.person.selfDeclared
        this.personalEmail = this.person.personalEmail
        this.rg = this.person.rg
        this.email_inst = this.person.email ? this.person.email.split('@')[0] : "";

        //Armazena nesse componente a data de nascimento da pessoa.
        if (this.person.birthday) {
          this.datanasc = this.person.birthday //.toISOString().substr(0, 10);
        }
        //Torna o campo de data LOCAL nulo quando a pessoa não existe.
        //Corrige o problema de aparecer a data do último usuário aberto.
        else {
          this.datanasc = null;
          this.age = null;
        }
    },
    // Calcula a idade com base na data de nascimento
    calculateAge(birthday) {
      const birthDate = new Date(birthday);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    //Emite o close para o componente pai, que fecha o dialog.
    close() {
      this.$emit("close");
      this.clean();
    },
    register() {
      const birthday = new Date(this.datanasc);
      const emailWithSuffix = this.email_inst.includes("@pge.ms.gov.br")
        ? this.email_inst
        : `${this.email_inst}@pge.ms.gov.br`;
        
      const event = this.update ? "itemUpdated" : "register";
      var newUser = {
        email: emailWithSuffix === "@pge.ms.gov.br" ? "" : emailWithSuffix,
        name: this.name,
        gender: this.gender,
        birthday: birthday,
        cpf: this.cpf,
        rg: this.rg,
        issuingBody: this.issuingBody,
        uf: this.uf,
        phone: this.phone.replace(/[^0-9]/g, ''),
        hasChildren: this.hasChildren === "Sim",
        maritalStatus: this.maritalStatus,
        selfDeclared: this.selfDeclared,
        personalEmail: this.personalEmail
      };
      const request = this.update
        ? axios.put(`${urlbackend}/rh/person/${this.id}`, { ...newUser })
        : axios.post(`${urlbackend}/rh/person`, newUser);

      request.then((res) => {
        if (res.status >= 200 && res.status <= 299) {

          newUser = res.data;
          newUser.birthday = new Date(newUser.birthday);
          // Emite o evento para o componente pai
          this.$emit(event, newUser);

          // Limpa o formulário e mostra a tabela de cargos
          if (event === "register") {
            this.showRoleTable = true;
          }
          if (event === "itemUpdated") {
            this.clean();
          }
        }
      }).catch((error) => {
        console.error("Erro ao registrar:", error);
        // Tratar erro, se necessário
      });
    },
    submitForm() {
      this.$refs.form.validate(); // Valida o formulário
      if (this.valid) {
        this.register(); // Chama o método de registro se o formulário for válido
      } else {
        console.log("Por favor, corrija os erros antes de enviar.");
      }
    },
    clean() {
      this.id = ""
      this.name = ""
      this.cpf = ""
      this.rg = ""
      this.issuingBody = ""
      this.uf = ""
      this.phone = ""
      this.hasChildren = ""
      this.maritalStatus = ""
      this.selfDeclared = ""
      this.personalEmail = ""
      this.email_inst = ""
      this.datanasc = ""
      this.gender = ""
    },
    formatDate(date) {
      if (!date) return null;

      const parsedDate = new Date(date);

      if (isNaN(parsedDate)) return null;

      const day = parsedDate.getUTCDate().toString().padStart(2, '0');
      const month = (parsedDate.getUTCMonth() + 1).toString().padStart(2, '0');
      const year = parsedDate.getUTCFullYear();

      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    validateDate(value) {
      if(!value) return 'Data inválida.';

      const [day, month, year] = value.split('/').map(Number);
      // Verifique se o mês é válido
      if (month < 1 || month > 12) {
        return "Mês inválido.";
      }
      // Verifique o dia com base no mês
      const daysInMonth = [31, (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0 ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (day < 1 || day > daysInMonth[month - 1]) {
        return "Dia inválido.";
      }

      // Verifique se o ano é válido
      if (year < 1000 || year > 9999) {
        return "Ano inválido.";
      }
      return true;
    },
  },
};
</script>

<style></style>